import React, {
  Fragment, useEffect, useMemo, useState,
} from "react"
import axios from 'redaxios';

import {
  camelCase,
  capitalize,
  get,
  groupBy,
  isEmpty,
  keyBy,
  map,
  orderBy,
  reduce,
  startCase,
  uniqBy,
} from 'lodash';

import { useStaticQuery, graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import sphere from '../../assets/images/spheres.png';

import { pluralize } from '../../frontend-shared/helpers';
import Spinner from '../../frontend-shared/components/Spinner';

import ButtonGetStarted from '../Buttons/ButtonGetStarted';
import SphereListItem from '../Elements/SphereListItem';

const labelMap = {
  automatic_shipment_track: 'Automatic shipment track & trace',
  branded_sender_id_for_sms: 'Branded sender ID for SMS',
  branded_email_notifications: 'Branded Notifications - Email',
  branded_notifications_sms: 'Branded Notifications - SMS',
  branded_notifications_whatsapp_for_business: 'Branded Notifications - WhatsApp for Business',
  carriyo_api: 'Carriyo API',
  carriyo_help_knowledge_base: 'Carriyo Help - Knowledge Base',
  premium_tracking_cx: 'Premium Tracking CX',
  real_time_alerts: 'Real-time Alerts',
  remove_power_by_carriyo: 'Remove “Power by Carriyo” tag',
  returns_webhooks: 'Return Webhooks',
  single_sign_on: 'Single Sign On (SAML 2.0 SSO)',
};

const Plan = keyBy(['enterprise']);
const Addon = keyBy([
  'carrier_slot', 'shipment_slot', 'user_seat',
], (key) => camelCase(key));

const labelize = (
  name, quantity,
) => pluralize(
  capitalize(startCase(name)).replace(/\s*slots?/i, '').trim(),
  quantity,
);

const Price = ({ billingCycle, price, size = 'md' }) => {
  const monthlyPrice = Math.round(price / (billingCycle === 'year' ? 12 : 1));
  return (
    <div className="flex flex-col items-center">
      <h5 className="mt-4">
        <span className={`${size === 'lg' ? 'xl:text-7xl ' : ''}text-4xl font-extrabold text-blue`}>
          {`$${monthlyPrice}`}
        </span>
        &nbsp;
      </h5>
      <p className="text-base capitalize text-blue mt-3 price_text flex flex-col items-center w-full">
        <span>per month</span>
        <span>{`(billed ${billingCycle === 'year' ? 'annually' : 'monthly'})`}</span>
      </p>
    </div>
  );
};

const PlanCard = ({
  billingCycle,
  enterpriseImage,
  plan = {},
  shipments,
}) => {
  const {
    addons = {}, code, details = [], name = '', price: baseCost = 0,
  } = plan;

  const shipmentSlot = get(addons, Addon.shipmentSlot, {});

  const {
    price: shipmentCost = 0,
    maxQuantity: maxShipments = 0,
    minQuantity: minShipments = 0,
  } = shipmentSlot;

  const exceedShipmentLimit = maxShipments < shipments;

  const totalPrice = baseCost + Math.max(
    0, Math.min(maxShipments, shipments) - minShipments,
  ) * shipmentCost;

  return (
    <div className="border bg-white sm:mt-0 mt-4 border-gray-200 rounded-lg shadow-lg relative">
      <div className="p-6 text-center enterprise-sol">
        <h2 className="xl:text-lg text-lg leading-6 font-medium text-blue uppercase">
          {name}
        </h2>
        {name === 'Enterprise' ? (
          <div className="flex flex-col items-center">
            <img
              loading="lazy"
              src={enterpriseImage.childImageSharp.fluid.srcWebp}
              alt={name}
              className="my-6 h-[92px] xl:h-[124px]"
            />
          </div>
        ) : (
          <>
            <Price billingCycle={billingCycle} price={totalPrice} size="lg" />
            {exceedShipmentLimit && <div className="absolute w-full h-full top-0 left-0 bg-white opacity-50" />}
          </>
        )}
      </div>
      <div className="pb-8 xl:px-4 px-0">
        <PrismicRichText
          field={details}
          components={{
            listItem: ({ text }) => {
              const addon = Object.values(Addon).some((addon) => addon === text);
              const { name, minQuantity } = get(addons, text, {});
              const label = addon ? ((name
                ? `${minQuantity} ${labelize(name, minQuantity)}`
                : labelize(text)
              )) : text;
              return (
                <SphereListItem>{label}</SphereListItem>
              );
            },
          }}
        />
      </div>
    </div>
  );
};

// FIXME: Plan parsing was copied from account-app
// Find a way to keep in sync
const groupByCode = (list) => {
  const codes = map(list, 'code');
  const group = groupBy(list, 'code');
  return reduce(codes, (acc, code) => ({ ...acc, [code]: group[code][0] }), {});
};

const decodePlans = (plans) => {
  const cycledPlans = groupBy(plans, 'billingCycle');
  const cycles = Object.keys(cycledPlans);
  cycles.forEach((cycle) => {
    const sortedPlans = orderBy(cycledPlans[cycle], ['price'])
      .filter(({ currency }) => !currency || currency === 'usd');
    sortedPlans.forEach((plan) => {
      const addons = orderBy(
        plan.addons,
        ['minQuantity', 'name'],
        ['desc', 'asc']
      );
      const groupedAddons = groupByCode(addons);
      plan.addons = groupedAddons;
    });
    const groupedPlans = groupByCode(sortedPlans);
    cycledPlans[cycle] = groupedPlans;
  });
  return cycledPlans;
};

const parsePrismicTable = (edges) => {
  const comparison = [];
  const entries = edges.flatMap(({
    node: {
      data: {
        plan_name: nameObject,
        name = nameObject.text,
        code = name.toLowerCase(),
        plan_details: rawDetails,
        details = rawDetails.richText,
        body = [],
        monthly_base_price = 0,
        yearly_base_price = 0,
        monthly_shipment_price = 0,
        yearly_shipment_price = 0,
        max_shipments = 0,
        min_shipments = 0,
        ...rest
      },
    },
  }) => {
    const base = { code, details, name };
    comparison.push({ ...base, body, price: monthly_base_price });
    const addonBase = {
      [Addon.shipmentSlot]: {
        code: Addon.shipmentSlot,
        maxQuantity: max_shipments,
        minQuantity: min_shipments,
      },
    };
    return [{
      ...base,
      addons: {
        [Addon.shipmentSlot]: {
          ...addonBase[Addon.shipmentSlot],
          price: monthly_shipment_price,
        },
      },
      billingCycle: 'month',
      price: monthly_base_price,
    }, {
      ...base,
      addons: {
        [Addon.shipmentSlot]: {
          ...addonBase[Addon.shipmentSlot],
          price: yearly_shipment_price,
        },
      },
      billingCycle: 'year',
      price: yearly_base_price,
    }];
  });
  const comparisonList = comparison.sort((x, y) => x.price - y.price).reduce(
    ({ head, body }, { body: rawBody, code, name }) => {
      const sections = map(rawBody, 'slice_type').filter(Boolean);
      return {
        head: [...head, { code, name }],
        body: sections.map((section) => {
          const newData = rawBody.find(
            ({ slice_type: slice }) => slice === section,
          ).primary;
          const features = Object.keys(newData);
          return {
            section,
            entries: features.map((feature) => {
              const oldData = (
                body.find(({ section: id }) => section === id) || { entries: [] }
              ).entries.find(({ feature: id }) => feature === id) || { entries: [] };
              return {
                feature,
                entries: [...oldData.entries, newData[feature]],
              };
            }),
          };
        }),
      };
    },
    { head: [], body: [] },
  );
  return [decodePlans(entries), comparisonList];
};

const Collapse = ({ children, show }) => show ? children : null;

const ComparisonGrid = ({ children, cols }) => (
  <div
    className="grid"
    style={{ gridTemplateColumns: `360px repeat(${cols}, minmax(auto, 1fr))` }}
  >
    {children}
  </div>
);

const PlanComparison = ({
  comparisonList: { head, body },
  show,
  toggleShow,
}) => {
  const [collapse, setCollapse] = useState({});
  const toggleCollapse = (index) => () => setCollapse({
    ...collapse,
    [index]: !collapse[index],
  });
  const button = (
    <div className="flex items-center justify-center mt-10">
      <button
        onClick={toggleShow}
        className="uppercase bg-white shadow-lg font-medium sm:text-xl text-base py-3 px-7 text-blue rounded-full no-underline mt-4 tracking-wide uppercase inline-block"
      >
        {show ? 'Close comparison list' : 'See full plan comparison'}
      </button>
    </div>
  );
  if (!show) return button;
  return (
    <>
      <div className="border bg-white p-8 rounded-2xl shadow-lg mt-24">
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <h2 className="text-3xl font-medium text-blue letter-spacing: -0.05em">Compare plans</h2>
                {body.map(({ section, entries }, index) => (
                  <ComparisonGrid cols={head.length} key={section}>
                    <div className={`${index ? 'mt-8 ' : ''}border-b text-base mt-3 font-bold flex items-center gap-2 text-blue  py-4 text-left`}>
                      {labelMap[section] || capitalize(startCase(section))}
                      <button
                        className="flex h-5 w-5 flex items-center rounded-full justify-center hover:bg-opacity-20 hover:bg-sky-700"
                        onClick={toggleCollapse(index)}
                        type="button"
                      >
                        <svg
                          fill="none"
                          height="7"
                          style={{ transform: collapse[index] ? 'rotate(180deg)' : undefined }}
                          viewBox="0 0 10 7"
                          width="10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10 9.53674e-07L5 7L-3.41715e-07 7.94465e-08L10 9.53674e-07Z" fill="#00063B" />
                        </svg>
                      </button>
                    </div>
                    {head.map(({ code, name }) => (
                      <div
                        className={`${index ? 'mt-8 ' : ''}border-b text-lg text-center uppercase font-medium text-gray-900 py-4 text-center`}
                        key={`${section}.${code}.name`}
                      >
                        {index ? null : name}
                      </div>
                    ))}
                    <Collapse show={!collapse[index]}>
                      {entries.map(({ feature, entries }) => (
                        <Fragment key={`${section}.${feature}`}>
                          <div className="border-b py-4 font-normal text-base text-blue">
                            {labelMap[feature] || capitalize(startCase(feature))}
                          </div>
                          {entries.map((entry, index) => (
                            <div
                              className="border-b w-full flex items-center justify-center"
                              key={`${section}.${feature}.${head[index].code}`}
                            >
                              <div>
                                {/^(true)|(yes)|(enable)|(active)$/i.test(entry) ? (
                                  <img
                                    loading="lazy"
                                    alt="Yes"
                                    className="mb-0"
                                    height="17"
                                    src={sphere}
                                    width="17"
                                  />
                                ) : (
                                  /^(no)|(false)|0|(none)$/i.test(entry) ? '' : entry
                                )}
                              </div>
                            </div>
                          ))}
                        </Fragment>
                      ))}
                    </Collapse>
                  </ComparisonGrid>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {button}
    </>
  );
};

const defaultMaxShipments = 10000;
const defaultMinShipments = 50;

const isFreePlan = (text) => /free/i.test(text);

const PricingTables = ({
  addon_section_heading,

  price_policy_text,
  trial_policy_text,
}) => {
  const [billingCycle, setBillingCycle] = useState('year');
  const [plans, setPlans] = useState({});
  const plansLoaded = !isEmpty(plans);

  const maxShipments = Object.values(plans).flatMap(Object.values).reduce(
    (maxPlan, plan) => (plan.price > maxPlan.price ? plan : maxPlan),
    { price: -Infinity },
  )?.addons?.[Addon.shipmentSlot].maxQuantity || defaultMaxShipments;

  const minShipments = Object.values(plans).flatMap(Object.values).reduce(
    (minPlan, plan) => (plan.price < minPlan.price ? plan : minPlan),
    { price: Infinity },
  )?.addons?.[Addon.shipmentSlot].minQuantity || defaultMinShipments;

  const [shipments, setShipments] = useState(minShipments);
  const numericShipments = Number(shipments || 0);
  const [showComparisonList, setShowComparisonList] = useState(false)

  useEffect(() => { 
    axios
      .get('https://mw.carriyo.com/account/public/subscription/plans')
      .then(({ data }) => setPlans(
        decodePlans(data.filter(({ code }) => !isFreePlan(code))),
      ));
  }, []);

  const updateBillingCycle =
    ({ target }) => setBillingCycle(target.value);

  const updateShipments = ({ numeric = false}) => (event) => {
    const value =
      typeof event === 'object' ? event.target.value : event;
    const sanitized = value.toString().replace(/[^0-9]/g, '');
    const update = numeric
      ? Math.max(minShipments, Math.min(maxShipments, Number(sanitized)))
      : sanitized;
    setShipments(update);
  };

  const numericUpdater = updateShipments({ numeric: true });

  useEffect(
    () => numericUpdater({ target: { value: shipments } }),
    [maxShipments, minShipments],
  );

  const {
    AllPricingTables,
    enterpriseImage,
    addonDetails,
  } = useStaticQuery(graphql`
    query pricingTablesQuery {
      enterpriseImage: file(relativePath: {eq: "enterprise-price.png"}) {
        childImageSharp {
          fluid {
            srcWebp
          }
        }
      }
      addonDetails : allPrismicAddon {
        edges {
          node {
            code: uid
            data {
              description {
                richText
              }
              name
            }
          }
        }
      }
      AllPricingTables : allPrismicPricingTables(sort: {fields: first_publication_date, order: ASC}) {
        edges {
          node {
            data {
              plan_name {
                text
              }
              plan_details {
                richText
              }
              monthly_base_price
              yearly_base_price
              monthly_shipment_price
              yearly_shipment_price
              max_shipments
              min_shipments
              body {
                ... on PrismicPricingTablesDataBodyCustomerEngagement {
                  id
                  slice_type
                  primary {
                    branded_track_and_trace
                    premium_tracking_cx
                    branded_feedback_loop
                    branded_pinpoint_customer_location
                    remove_power_by_carriyo
                    branded_email_notifications
                    branded_notifications_sms
                    branded_notifications_whatsapp_for_business
                    custom_domains
                  }
                }

                ... on PrismicPricingTablesDataBodyShippingAndCarriers {
                  id
                  slice_type
                  primary {
                    automatic_shipment_booking
                    automatic_shipment_track
                    carrier_labels
                    carriyo_label
                    custom_labels
                    shipping_manifests
                    service_level_management
                    carrier_capacity_management
                    carrier_network_management
                    custom_carrier_connectors
                    custom_attributes
                  }
                }

                ... on PrismicPricingTablesDataBodyReturnsManagement {
                  id
                  slice_type
                  primary {
                    branded_returns_portal
                    returns_management
                    returns_webhooks
                  }
                }

                ... on PrismicPricingTablesDataBodyIntelligenceReporting {
                  id
                  slice_type
                  primary {
                    control_tower
                    real_time_alerts
                    shipment_history_report
                    shipment_ageing_report
                    transit_time_report
                    shipment_error_report
                    regional_analysis_report
                    settlement_report
                    carrier_performance_comparison_report
                    service_level_report
                    customer_feedback_report
                  }
                }

                ... on PrismicPricingTablesDataBodyIntegration {
                  id
                  slice_type
                  primary {
                    carriyo_api
                    carriyo_connectors
                    carriyo_webhooks
                    single_sign_on
                  }
                }

                ... on PrismicPricingTablesDataBodySupport {
                  id
                  slice_type
                  primary {
                    carriyo_help_knowledge_base
                    support_portal_tickets
                    email_support
                    free_sandbox_account
                    platform_extension_services
                    dedicated_account_manager
                    managed_service_levels
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [prismicPlans, comparisonList] = useMemo(
    () => parsePrismicTable(
      AllPricingTables.edges.filter(
        ({ node: { data: { plan_name } } }) => !isFreePlan(plan_name.text),
      ),
    ),
    [AllPricingTables],
  );

  const plansByCycle = useMemo(() =>
    Object.values({
      ...get(plans, billingCycle, get(
        prismicPlans, billingCycle, {},
      )),
      enterprise: {
        code: Plan.enterprise,
        name: 'Enterprise',
        ...get(prismicPlans, [billingCycle, Plan.enterprise], {}),
      },
    }).map((plan) => ({
      ...get(prismicPlans, [billingCycle, plan.code], {}),
      ...plan,
    })),
    [billingCycle, plans, prismicPlans],
  );

  const addonPriceSetsByCode = Object.fromEntries(
    Object.entries(
      groupBy(
        Object
          .values(plans[billingCycle] || {})
          .flatMap(({ code, addons }) => 
            Object.values(addons).map((addon) => ({ ...addon, billingCycle, plan: code })),
          )
          .filter(([code]) => code !== 'shipment_slot'),
        'code',
      ),
    ).map(([code, entries]) => [code, uniqBy(entries, 'price')]),
  );

  return (
    <>
      <div className="max-w-5xl mx-auto px-4 flex sm:flex-row flex-col justify-between">
        <div className="w-full flex flex-col">
          <div className="flex flex-row gap-10 items-baseline">
            <input
              className='bg-white rounded-full shadow-lg text-blue text-lg h-12 w-32 pl-5'
              min={minShipments}
              max={maxShipments}
              onChange={updateShipments({ numeric: false })}
              onBlur={numericUpdater}
              type="number"
              value={shipments}
            />
            <p className="text-blue text-sm">
              Number of shipments per month
            </p>
          </div>
          <div className="lg:pt-4 sm:pt-1 pt-4 sm:w-3/5 w-full h-2">
            <Slider
              min={minShipments}
              max={maxShipments}
              step={100}
              defaultValue={100}
              value={numericShipments}
              onChange={numericUpdater}
            />
          </div>
        </div>
        <div className="w-full flex sm:flex-col flex-col-reverse sm:items-end items-left sm:mt-0 mt-16">
          <p className="text-sm text-blue md:mt-0 mt-5">Save 15% with our annual plan</p>
          <div className="sm:w-auto w-50 flex rounded-full flex-row justify-center">
            <div class="selector sm:w-auto w-100 bg-white rounded-full">
              <div class="selecotr-item font1">
                <input type="radio" id="radio1"
                  name="selector"
                  value="year"
                  class="selector-item_radio"
                  onChange={updateBillingCycle}
                  checked={billingCycle === "year"}
                />
                <label htmlFor="radio1" class="selector-item_label">
                  ANNUAL
                </label>
              </div>
              <div class="selecotr-item font1">
                <input type="radio" id="radio2"
                  name="selector"
                  value="month"
                  class="selector-item_radio"
                  onChange={updateBillingCycle}
                  checked={billingCycle === "month"}
                />
                <label htmlFor="radio2" class="selector-item_label">MONTHLY</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-5xl mx-auto py-4 px-4">
        <div className="w-full grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:mt-6 sm:gap-6">
          {Object.values(plansByCycle).map((plan) => (
            <PlanCard
              billingCycle={billingCycle}
              enterpriseImage={enterpriseImage}
              key={plan.code}
              loading={!plansLoaded && isEmpty(plan.addons)}
              plan={plan}
              shipments={numericShipments}
             />
          ))}
        </div>
        <div className="w-full flex flex-col items-center py-8 gap-4">
          <div className="flex flex-col items-center xl:text-lg text-base text-blue pb-8">
            {price_policy_text}
          </div>
          <ButtonGetStarted />
          <div className="xl:text-lg text-base text-blue">
            {trial_policy_text}
          </div>
        </div>
        <PlanComparison
          comparisonList={comparisonList}
          show={showComparisonList}
          toggleShow={() => setShowComparisonList(!showComparisonList)}
        />
      </div>
      <div className="max-w-5xl mx-auto p-4 mt-24">
        <div className="flex flex-row gap-4 items-center md:mb-8 mb-6 text-blue">
          <h2 className="lg:text-5xl md:text-4xl text-3xl font-medium mb-0 tracking-tighter">
            {addon_section_heading}
          </h2>
        </div>
        <div className="w-full flex flex-col gap-6">
          {addonDetails.edges.map(({
            node: {
              code,
              data: {
                description,
                name,
              },
            },
          }) => (
            <div className="grid grid-cols-2 w-full shadow-xl bg-white rounded-xl p-8">
              <div className="w-full items-center flex flex-col gap-8">
                <h3 className="xl:text-lg text-lg leading-6 font-medium text-blue uppercase">
                  {name}
                </h3>
                <div className="w-full flex flex-row justify-center gap-8">
                  {!addonPriceSetsByCode[code] ? (
                    <div className="text-blue flex flex-col justify-center py-[61px]">
                      <Spinner />
                    </div>
                  ) : addonPriceSetsByCode[code].map(({ billingCycle, plan, price }) => (
                    <div className="flex flex-col items-center gap-1 text-blue">
                      {addonPriceSetsByCode[code].length > 1 && (
                        <h4 className="xl:text-lg text-lg leading-6 font-medium uppercase">
                          {plan}
                        </h4>
                      )}
                      <Price billingCycle={billingCycle} price={price} />
                      {addonPriceSetsByCode[code].length === 1 && (
                        <div className="xl:text-lg text-lg leading-6 font-medium uppercase">
                          &nbsp;
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col justify-center pt-8">
                <PrismicRichText
                  field={description.richText}
                  components={{ listItem: SphereListItem }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
};

export default PricingTables
