import * as React from "react"
import { graphql, navigate } from "gatsby"

import { PrismicRichText } from "@prismicio/react"

import { FormButton } from '../../frontend-shared/components';

import TrustedBrands from '../Elements/TrustedBrands';

import ButtonGetStarted from '../Buttons/ButtonGetStarted';

import PricingTables from './PricingTables';

const HeroSection = ({
  slice: {
    primary: {
      heading,
      sub_heading,
      addon_section_heading,
      price_policy_text,
      trial_policy_text,
      startup_section_heading,
      startup_section_content,
    },
  },
}) => {
  return (
    <div className="background1">
      <div className="sm:pt-48 pt-28 sm:pb-16 pb-8 relative">
        <div className="bg2 absolute top-0 right-0"></div>
        <div className="max-w-4xl mx-auto px-4 pt-10 sm:pt-0 md:pt-10 pb-10 md:mb-10">
          <div className="md:text-center text-left">
            <h1 className="lg:text-7xl md:text-6xl text-4xl md:mb-9 mb-5 text-blue font-medium">{heading?.text}</h1>
            <p className="md:text-xl text-base md:mx-auto text-blue font-normal">{sub_heading}</p>
            <div className="flex flex-row justify-center w-full">
              <ButtonGetStarted />
            </div>
          </div>
        </div>
        <PricingTables
          addon_section_heading={addon_section_heading}
          price_policy_text={price_policy_text}
          trial_policy_text={trial_policy_text}
        />
        <div className="w-full max-w-5xl p-4 mx-auto mt-24">
          <div className="w-full py-8 px-12 bg-gradient rounded-xl flex flex-col items-center gap-8 text-center">
            <h2 className="text-white lg:text-5xl md:text-4xl text-3xl font-medium md:mb-4 mb-2 tracking-tighter">
              {startup_section_heading}
            </h2>
            <PrismicRichText
              field={startup_section_content?.richText}
              components={{
                paragraph: ({ children }) => (
                  <p className="mb-0 text-white xl:text-lg text-base max-w-full">
                    {children}
                  </p>
                ),
              }}
            />
            <FormButton
              onClick={() => navigate('/contact')}
              size="lg"
            >
              CONTACT US
            </FormButton>
          </div>
        </div>
      </div>
      <TrustedBrands theme="light" className="pt-16 pb-28" />
    </div>
  )
}

export default HeroSection

export const query = graphql`
  fragment pricingHeroSlice on PrismicPricingPageDataBodyHero {
    id
    slice_type
    primary {
      heading {
        text
        html
      }
      sub_heading
      addon_section_heading
      price_policy_text
      trial_policy_text
      startup_section_heading
      startup_section_content {
        richText
      }
    }
  }
`
